import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`PRIVACY POLICY`}</h1>
    <p>{`Last Revised: 2021-10-28`}</p>
    <p>{`Your privacy is important to us, so RedPay Sp. z o.o. from Poland, has created the following Privacy Policy ("Policy") to let you know what information we collect when you visit our Site `}<a parentName="p" {...{
        "href": "http://archforges.com"
      }}>{`http://archforges.com`}</a>{` ("Site"), why we collect it and how we use it.`}</p>
    <p>{`The terms "You," "Your," "Yours" and "User" refer to the entity/person/organization using our Site.
When this Policy mentions "We", "Us," and "Our" it refers to RedPay Sp. z o.o. and its subsidiaries and affiliates.`}</p>
    <p>{`This Privacy Policy is governed by our `}<a parentName="p" {...{
        "href": "/terms-of-service"
      }}>{`Terms of Services`}</a>{`.`}</p>
    <p>{`For any questions regarding this Policy or any requests regarding the processing of personal data, please contact us at `}<a parentName="p" {...{
        "href": "mailto:website@archforges.com"
      }}>{`website@archforges.com`}</a>{`.`}</p>
    <h2>{`1. INFORMATION WE COLLECT FROM YOU`}</h2>
    <p>{`We collect the information You provide to us and this information is necessary for the adequate performance of the contractual arrangement which is in place between You and us and allow us to comply with our legal obligations.`}</p>
    <ul>
      <li parentName="ul">{`Account Signup Information. When You create the account, we ask You to provide the signup information, such as Email, Name, Surname, Phone, Username, Passwords, Address.`}</li>
      <li parentName="ul">{`Payment Information. To order and use features of Site, we may require you to provide certain financial information in order to facilitate the processing of payments. We collect your Credit or debit card number, Credit or debit card type, Credit or debit card expiration date, Billing address, Tax number, Name and surname.`}</li>
      <li parentName="ul">{`Login information. We collect Login information if You are logging to our account with Authentication Data.`}</li>
      <li parentName="ul">{`Other Personal Information Provided by You. We may collect other data provided by You from surveys, feedback, financial information (purchase history), REGON Company number, VAT TAX Number and other similar data.`}</li>
    </ul>
    <h2>{`2. INFORMATION WE COLLECT AUTOMATICALLY`}</h2>
    <p>{`When you use our Site or contact us directly we may collect information, including your personal information, about the way you act in our Site, the services You use and how You use them.
This information is necessary for the adequate performance of the contract between You and us, to enable us to comply with legal obligations and given our legitimate interest in being able to provide and improve the functionalities of the Site.`}</p>
    <ul>
      <li parentName="ul">{`Log data and Device information. We automatically collect log data and device information when you access and use the Site, even if you have not created an Account or logged in. That information includes, among other things: Operating system, Internet protocol (IP) addresses, Browser type, Date/time stamp, Referring/exit pages.`}</li>
      <li parentName="ul">{`Tracking technologies and Cookies. We use Cookies, Beacons, Tags, CI codes (click tracking), ISC (source tracking), ITC (item tracking codes), Phone model, Device ID, Customer number. We also automatically collect information about device’s operating system, .`}</li>
      <li parentName="ul">{`Geo-location data. We collect information about your approximate location as determined by data such as your IP address to offer you an improved user experience. Such data may be collected only when you access the Site using your device.`}</li>
      <li parentName="ul">{`Usage information. We use a tool called "Google Analytics" to collect information about your interactions with the Site (what pages you visit, such as the pages or content you view, your searches for Listings, bookings you have made, and other actions on the Site. In consequence, Google, Inc. plants a permanent cookie on your web browser to identify you as a unique user the next time you visit this Site). For more information please visit `}<a parentName="li" {...{
          "href": "https://policies.google.com/privacy"
        }}>{`Google`}</a>{`.`}</li>
      <li parentName="ul">{`Channel Type`}</li>
    </ul>
    <h2>{`3. THE WAY WE USE YOUR INFORMATION`}</h2>
    <p>{`We process your information adhering to the general data processing principles.
We may use the information we collect through our Site for a number of reasons, including to:`}</p>
    <ul>
      <li parentName="ul">{`to identify user`}</li>
      <li parentName="ul">{`to create statistics and analyze market`}</li>
      <li parentName="ul">{`to customize marketing`}</li>
      <li parentName="ul">{`to improve services`}</li>
      <li parentName="ul">{`to comply with applicable laws`}</li>
      <li parentName="ul">{`to send billing information`}</li>
      <li parentName="ul">{`to manage user orders`}</li>
      <li parentName="ul">{`to contact user`}</li>
      <li parentName="ul">{`to provide support`}</li>
    </ul>
    <p>{`We will normally collect personal information from you only where we have your consent to do so, where we need the personal information to perform a contract with you, or where the processing is in our legitimate business interests.`}</p>
    <h2>{`4. DIRECT MARKETING`}</h2>
    <p>{`We may use your provided contact details for direct marketing. These direct marketing offers, depending on your preferences, may be personalized taking into account any other information which you have provided to us (e.g. location, social media profile information, etc.) or we have collected or generated from other sources as described below.
If you wish to withdraw the consent for direct marketing, and refuse to receive information from us, you may exercise such option at any time you wish by following the instructions to unsubscribe in the received email, sending an email to `}<a parentName="p" {...{
        "href": "mailto:newsletter@archforges.com"
      }}>{`newsletter@archforges.com`}</a>{`, clicking on a link for that purpose at the bottom of our e-mail with the newsletters.`}</p>
    <h2>{`5. HOW WE MAY SHARE YOUR INFORMATION`}</h2>
    <p>{`We may share your information with third parties that we use to provide and support our Services:`}</p>
    <ul>
      <li parentName="ul">{`Analytics - Google Analytics`}</li>
    </ul>
    <p>{`We may also disclose your information to third parties:`}</p>
    <ul>
      <li parentName="ul">{`where required by law or regulatory requirement, court order or other judicial authorization;`}</li>
      <li parentName="ul">{`in response to lawful requests by public authorities, including for the purposes of meeting national security and law enforcement requirements;`}</li>
      <li parentName="ul">{`in connection with the sale, transfer, merger, bankruptcy, restructuring or other reorganization of a business;`}</li>
      <li parentName="ul">{`to protect or defend our rights, interests or property, or that of third parties; (e) to investigate any wrongdoing in connection with our products and services;`}</li>
      <li parentName="ul">{`and to protect the vital interests of an individual.`}</li>
    </ul>
    <h2>{`6. COOKIES`}</h2>
    <p>{`Cookies are small text files stored by your browser on your computer when you visit our Site. We use cookies to improve our Site and make it easier to use. Cookies permit us to recognize users and avoid repetitive requests for the same information.
Please check our `}<a parentName="p" {...{
        "href": "cookie-policy/"
      }}>{`Cookie Policy`}</a>{` to find more information about cookies we use.
You may find more information about how to delete cookies, as well as the other useful information related to the use of the cookies, on the website `}<a parentName="p" {...{
        "href": "http://www.allaboutcookies.org/"
      }}>{`http://www.allaboutcookies.org/`}</a>{`.`}</p>
    <h2>{`7. INFORMATION FROM MINORS`}</h2>
    <p>{`This site and our Services are not intended for or directed to persons under the age of 18. We do not knowingly collect or ask for information from minors. We do not knowingly allow minors to use our Site or Services.
We will delete any information we discover is collected from a minor. Please contact us using the contact details below if you believe we might have information about a minor.`}</p>
    <h2>{`8. SENSITIVE INFORMATION`}</h2>
    <p>{`We do not collect sensitive information such as political opinions, religious or philosophical beliefs, racial or ethnic origin, genetic data, biometric data, health data or data related a sexual orientation.
Please do not send, upload, or provide us any sensitive data and contact us using the contact details below if you believe that we might have such information. We have a right to delete any information we believe it might contain sensitive data.`}</p>
    <h2>{`9. PAYMENT INFORMATION`}</h2>
    <p>{`To order and use our services we may require you to provide certain financial information to facilitate the processing of payments. We may collect your name, credit or debit card type, expiration date, billing address, the certain digits of your card number and details of your purchase. The exact personal data collected will vary depending on the payment method.`}</p>
    <h2>{`10. THIRD PARTY LINKS`}</h2>
    <p>{`Our Site may have links to other websites. Please review their privacy policies to learn more about how they collect and use your personal data, because we do not control their policies and personal data processing practices.`}</p>
    <h2>{`11. RETENTION`}</h2>
    <p>{`We retain your personal information to provide services to you and as otherwise necessary to comply with our legal obligation, resolve disputes, and enforce our agreements.
We will retain your personal information as long as we need it to provide services to you, unless we are otherwise required by law or regulations to retain your personal information longer.`}</p>
    <h2>{`12. SECURITY`}</h2>
    <p>{`We have implemented security measures designed to protect the personal information you share with us, including physical, electronic and procedural measures. Among other things, we regularly monitor our systems for possible vulnerabilities and attacks.
Regardless of the measures and efforts taken by us, the transmission of information via internet, email or text message is not completely secure. We do not guarantee the absolute protection and security of your personal information or any other User Content you upload, publish or otherwise share with us or anyone else.
We therefore encourage you to avoid providing us or anyone with any sensitive information of which you believe its disclosure could cause you substantial or irreparable harm.
If you have any questions regarding the security of our Site or Services, you are welcome to contact us at `}<a parentName="p" {...{
        "href": "mailto:website@archforges.com"
      }}>{`website@archforges.com`}</a>{`.`}</p>
    <h2>{`13. YOUR RIGHTS`}</h2>
    <p>{`You are entitled to a range of rights regarding the protection of your personal information. Those rights are:`}</p>
    <ul>
      <li parentName="ul">{`The right to access the information we have about you. If you wish to access your personal information that we collect, you can do so at any time by contacting us using the contact details provided below.`}</li>
      <li parentName="ul">{`The right to rectify inaccurate information about you. You can correct, update or request deletion of your personal information by contacting us using the contact details provided below.`}</li>
      <li parentName="ul">{`The right to object the processing. When we rely on your consent to process your personal information, you may withdraw consent at any time by contacting us using the contact details provided below. This will not affect the lawfulness of processing prior to the withdrawal of your consent.`}</li>
      <li parentName="ul">{`The right to lodge a complaint. You can raise questions or complaints to the national Data Protection Agency in your country of residence in the event where your rights may have been infringed. However, we recommend attempting to reach a peaceful resolution of the possible dispute by contacting us first.`}</li>
      <li parentName="ul">{`The right to erase any data concerning you. You may demand erasure of data without undue delay for legitimate reasons, e.g. where data is no longer necessary for the purposes it was collected, or where the data has been unlawfully processed.`}</li>
    </ul>
    <h2>{`14. APPLICATION OF POLICY`}</h2>
    <p>{`This Policy applies only to the services offered by our Company. Our Policy does not apply to services offered by other companies or individuals, including products or sites that may be displayed to you in search results, sites that may include our services or other sites linked from our Site or Services.`}</p>
    <h2>{`15. AMENDMENTS`}</h2>
    <p>{`Our Policy may change from time to time. We will post any Policy changes on our Site and, if the changes are significant, we may consider providing a more explicit notice (including, for certain services, email notification of Policy changes).`}</p>
    <h2>{`16. ACCEPTANCE OF THIS POLICY`}</h2>
    <p>{`We assume that all Users of this Site have carefully read this document and agree to its contents. If someone does not agree with this Policy, they should refrain from using our Site. We reserve the right to change our Policy at any time and inform by using the way as indicated in Section 15. Continued use of this Site implies acceptance of the revised Policy.`}</p>
    <h2>{`17. FURTHER INFORMATION`}</h2>
    <p>{`If you have any further questions regarding the data we collect, or how we use it, then please feel free to contact us at the details as indicated above.
Users from EU and EEA can easily contact our European representative: Paweł Pawłowski `}<a parentName="p" {...{
        "href": "mailto:website@archforges.com"
      }}>{`website@archforges.com`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      