import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"
import { Link } from "gatsby"
import privacyPolicy from "../assets/images/privacy-policy.png"
import Policy from "../markdown/privacy_policy.md"

const PrivacyPolicy = () => {
  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle="Privacy Policy"
        homePageText="Home"
        homePageUrl="/"
        activePageText="Privacy Policy"
      />
      <section className="privacy-policy-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="privacy-policy-content">
                <img src={privacyPolicy} alt="pp" />
                <Policy />

                <blockquote className="blockquote">
                  <p>
                    We collect certain data from you directly, like information
                    you enter yourself, data about your participation in
                    courses, and data from third-party platforms you connect
                    with Arch Forge Solutions. We also collect some data
                    automatically, like information about your device and what
                    parts of our services you interact with or spend time using.
                  </p>
                </blockquote>
              </div>
            </div>

            <div className="col-lg-4 col-md-12">
              <aside className="widget-area">
                <div className="widget widget_insight">
                  <ul>
                    <li>
                      <Link to="/about-us">About Us</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                    <li className="active">
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/terms-of-service">Terms of Service</Link>
                    </li>
                  </ul>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  )
}

export default PrivacyPolicy
